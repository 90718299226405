import React from 'react';
import ReactDOM from 'react-dom/client';

const App = () => (
  <div style={{ 
    fontFamily: 'Arial, sans-serif',
    maxWidth: '600px',
    margin: '100px auto',
    padding: '40px',
    textAlign: 'center',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
  }}>
    <div style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#1a5276', marginBottom: '20px' }}>
      Tyre Compare
    </div>
    <h1>Coming Soon</h1>
    <p>We're working on creating the best tyre price comparison platform for UK drivers.</p>
    <p>Our site is currently under development and will be launching soon.</p>
    <p>Thank you for your patience!</p>
  </div>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);